<template>
  <b-card>
    <div>
      <b-row>
        <b-col md="3">
          <!-- ClientName  -->
          <label class="dateLabel">{{ $t("customerName") }}</label>
          <g-field
            id="customerName"
            :value.sync="filter.ClientName"
            name="ClientName"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="3">
          <!-- ClientEmail  -->
          <label class="dateLabel">{{ $t("email") }}</label>
          <g-field
            id="ClientEmail"
            :value.sync="filter.Email"
            name="ClientEmail"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="3">
          <!-- ContactPerson  -->
          <label class="dateLabel">{{ $t("contactPerson") }}</label>
          <g-field
            id="contactPerson"
            :value.sync="filter.ContactPerson"
            name="ContactPerson"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="3">
          <!-- ContactPhone  -->
          <label class="dateLabel">{{ $t("phone") }}</label>
          <g-field
            id="ContactPhone"
            :value.sync="filter.ContactPhone"
            name="ContactPhone"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="3">
          <!-- DomainName  -->
          <label class="dateLabel">{{ $t("domin") }}</label>
          <g-field
            id="DomainName"
            :value.sync="filter.DomainName"
            name="DomainName"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
        <b-col md="3">
          <!-- ClientAddress  -->
          <label class="dateLabel">{{ $t("address") }}</label>
          <g-field
            id="ClientAddress"
            :value.sync="filter.Address"
            name="ClientAddress"
            v-silent-search
            @keydown.enter="
              (v) => {
                refreshItems();
              }
            "
          />
        </b-col>
      </b-row>
    </div>
    <div class="my-2">
      <div class="row justify-content-between">
        <div class="col-md-4 col-12"></div>
        <div
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12"
        >
          <vue-excel-xlsx
            :data="itemsArray"
            :columns="tableColumns"
            :file-name="'PluginClients'"
            :sheet-name="'xlsxSheet'"
            class="btn btn-relief-success ml-1 p-0 ptn-sm"
          >
            <img
              src="@/assets/images/icons/xls.jpg"
              alt=""
              style="height: 39px; width: auto"
            />
          </vue-excel-xlsx>
          <!-- <b-button
                variant="relief-danger"
                class="ml-1 p-0 ptn-sm"
                @click="printDocument"
              >
                <img src="@/assets/images/icons/pdf.jpg" alt="" style="height: 39px;width: auto;" />
              </b-button> -->
        </div>
      </div>
    </div>
    <g-table
      ref="currentUsers-table"
      :items="itemsSearchProvider"
      :columns="tableColumns"
      :noAction="true"
      responsive
      perPage="25"
      style="font-size: 14px"
      :totalRows="totalRows"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :createButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
      :tbody-tr-class="
        (item) => {
          if (item && item.endDate < today) return 'bg-light-danger';
        }
      "
    >
    <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('active')"
            variant="flat-success"
            size="sm"
            @click="active(item)"
          >
            <feather-icon
              icon="CheckCircleIcon"
              size="18"
              class="mx-0 clickable text-success"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
  <script src="@/pages/Shared/html2canvas.js"></script>
  <script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      filter: {
        ClientName: '',
        Email: '',
        ContactPerson: '',
        ContactPhone: '',
        DomainName: '',
        Address: '',
        PlanId: '',
        StartDate: '',
        EndtDate: '',
        SubscriptionEndIn: ''
      },
      isTableBusy: false,
      totalRows: 0,
      pathName: this.$route.name,
      itemsArray: [],
      plans: [],
      hideActions: false
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'name',
          field: 'name',
          label: this.$t('customerName'),
          sortable: true
        },
        {
          key: 'email',
          field: 'email',
          label: this.$t('email'),
          sortable: true,
        },
        {
          key: 'contactPerson',
          field: 'contactPerson',
          label: this.$t('contactPerson'),
          sortable: true,
        },
        {
          key: 'contactPhone',
          field: 'contactPhone',
          label: this.$t('phone'),
          sortable: true
        },
        {
          key: 'domainName',
          field: 'domainName',
          label: this.$t('domin'),
          sortable: true,
          formatter: (key, value, item) => {
            return `<span class="badge badge-light-primary">${item.domainName}</span>`;
          },
        },
        {
          key: 'address',
          field: 'address',
          label: this.$t('address'),
          sortable: true
        },
        {
          key: 'planArabicName',
          field: 'planArabicName',
          label: this.$t('plan'),
          sortable: true
        },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getPlans();
  },
  methods: {
    active(item) {
      this.confirmActivate(
            {
              text: this.$t('areYouSureYouWantToActivate'),
            },
            () => {
              this.get({
                url: `clients/completeClientPayment/${item.tenantId}`,
              }).then(() => {
                this.doneAlert({ text: this.$t('changeSuccessfully') });
                this.refreshItems();
              });
            }
          );
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function (canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = canvas.height * imgWidth / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      setTimeout(() => {
        this.pdfExport('Plugin-ClientsNotActive')
        this.hideActions = false
      }, 500);
    },
    getPlans() {
      this.get({ url: 'plans' }).then((data) => {
        this.plans = data;
      })
    },
    refreshItems() {
      this.$refs['currentUsers-table'].refreshTable();
    },
    itemsSearchProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&PaymentPending=true&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `clients${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            this.totalVals += item.debentureValue;
            item.startDate = this.getDate(item.startDate)
            item.endDate = this.getDate(item.endDate)
            // item.lastInvoiceDate = item.lastInvoiceDate ? this.getDate(item.lastInvoiceDate) : null;
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
  },
};
  </script>

  <style>
.canseledVouchers {
  text-decoration: line-through;
  color: firebrick;
}
</style>
