var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("customerName")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"customerName","value":_vm.filter.ClientName,"name":"ClientName"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "ClientName", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("email")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"ClientEmail","value":_vm.filter.Email,"name":"ClientEmail"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "Email", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("contactPerson")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"contactPerson","value":_vm.filter.ContactPerson,"name":"ContactPerson"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "ContactPerson", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("phone")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"ContactPhone","value":_vm.filter.ContactPhone,"name":"ContactPhone"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "ContactPhone", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("domin")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"DomainName","value":_vm.filter.DomainName,"name":"DomainName"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "DomainName", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('label',{staticClass:"dateLabel"},[_vm._v(_vm._s(_vm.$t("address")))]),_c('g-field',{directives:[{name:"silent-search",rawName:"v-silent-search"}],attrs:{"id":"ClientAddress","value":_vm.filter.Address,"name":"ClientAddress"},on:{"update:value":function($event){return _vm.$set(_vm.filter, "Address", $event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function (v) {
              _vm.refreshItems();
            }).apply(null, arguments)}}})],1)],1)],1),_c('div',{staticClass:"my-2"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-md-4 col-12"}),_c('div',{staticClass:"d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12"},[_c('vue-excel-xlsx',{staticClass:"btn btn-relief-success ml-1 p-0 ptn-sm",attrs:{"data":_vm.itemsArray,"columns":_vm.tableColumns,"file-name":'PluginClients',"sheet-name":'xlsxSheet'}},[_c('img',{staticStyle:{"height":"39px","width":"auto"},attrs:{"src":require("@/assets/images/icons/xls.jpg"),"alt":""}})])],1)])]),_c('g-table',{ref:"currentUsers-table",staticStyle:{"font-size":"14px"},attrs:{"items":_vm.itemsSearchProvider,"columns":_vm.tableColumns,"noAction":true,"responsive":"","perPage":"25","totalRows":_vm.totalRows,"excelButton":{ visiable: false },"pdfButton":{ visiable: false },"createButton":{ visiable: false },"searchInput":{ visiable: false },"tbody-tr-class":function (item) {
        if (item && item.endDate < _vm.today) { return 'bg-light-danger'; }
      }},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
      var item = ref.item;
return [_c('div',{staticClass:"text-nowrap"},[_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:(_vm.$t('active')),expression:"$t('active')",modifiers:{"hover":true,"top":true}}],attrs:{"variant":"flat-success","size":"sm"},on:{"click":function($event){return _vm.active(item)}}},[_c('feather-icon',{staticClass:"mx-0 clickable text-success",attrs:{"icon":"CheckCircleIcon","size":"18","id":("invoice-row-" + (item.id) + "-delete-icon")}})],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }